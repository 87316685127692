<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>
          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>

        <main v-if="is_API_loaded || isNewBlog" class="space-y-5">
          <!-- 如果是新增部落格，就隱藏此區塊 -->
          <div v-if="!isNewBlog" class="flex w-full flex-wrap items-center justify-between">
            <h1 class="text-xl font-medium text-gray-text_dark">{{ title }}</h1>
            <div class="flex items-center space-x-2 flex-shrink-0">
              <a :href="_wlink" target="_blank">
                <vs-button size="large" transparent>
                  <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
                  <span class="font-medium">檢視</span>
                </vs-button>
              </a>
            </div>
          </div>

          <!-- <h1 v-if="!isNewBlog" class="text-xl font-medium text-gray-text_dark lg:hidden">{{ title }}</h1> -->

          <!-- 新增部落格後顯示 -->
          <transition name="fade">
            <section v-if="alertAfterCreated">
              <vs-alert color="success" gradient>
                <template #icon>
                  <svg-icon icon-class="success" className="xxl"></svg-icon>
                </template>
                <template #title> {{ alertTitle }} 已新增成功 </template>
                <a :href="_wlink" target="_blank">在前台中檢視</a> 或
                <router-link :to="{ name: 'menus' }">加入目錄選單</router-link>
              </vs-alert>
            </section>
          </transition>

          <!-- 2. -->
          <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
            <!-- 2.1.1 -->
            <div class="w-full md:w-14/20 space-y-4 flex-shrink-0">
              <article class="box">
                <div>
                  <h1 class="title">標題 *</h1>
                  <div class="relative">
                    <ValidationProvider mode="aggressive" name="標題" rules="required|max:50" v-slot="{ errors }">
                      <vs-input @input="titleChange" class="w-full" v-model="title" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </div>
              </article>

              <!-- 2.1.2 SEO -->
              <article class="box overflow-hidden" :class="[seoSectionOpen ? '' : 'h-16']">
                <div class="flex items-center justify-between">
                  <h1 class="bigTitle">SEO 搜尋引擎最佳化</h1>
                  <vs-button v-if="!seoSectionOpen" @click="seoSectionOpen = true" transparent color="success">編輯 SEO</vs-button>
                </div>

                <section
                  class="transition-transform duration-100 transform scale-y-0 origin-top space-y-6"
                  :class="[seoSectionOpen ? 'scale-y-100' : '']"
                >
                  <div class="space-y-1">
                    <h1 class="text-success font-medium text-base">{{ seoTitle }}</h1>
                    <p class="text-green-700 text-sm break-all" v-if="seoUrl">
                      {{ $store.state.getWebData.param.wlink }}/blog/{{ seoUrl }}
                    </p>
                    <p class="text-sm">{{ seoContent }}</p>
                  </div>
                  <div>
                    <h1 class="title">網頁標題</h1>
                    <ValidationProvider mode="aggressive" name="網頁標題" rules="max:100" v-slot="{ errors }">
                      <vs-input class="w-full" v-model="seoTitle" />
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>

                  <div>
                    <h1 class="title">網頁網址</h1>
                    <div class="flex items-center border border-gray-border rounded-xl pl-3">
                      <div>
                        <span>{{ $store.state.getWebData.param.wlink }}/blog/</span>
                      </div>
                      <ValidationProvider class="w-full" mode="aggressive" name="網頁網址" rules="max:100" v-slot="{ errors }">
                        <vs-input type="url" id="noBorderInput" class="w-full" v-model.trim="seoUrl" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div>
                    <h1 class="title">網頁描述</h1>

                    <ValidationProvider class="w-full" mode="aggressive" name="網頁描述" rules="max:200" v-slot="{ errors }">
                      <myTextarea autoHeight v-model="seoContent"></myTextarea>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </section>
              </article>
            </div>

            <!-- 2.2.1 -->
            <div class="flex-grow space-y-4">
              <div class="box">
                <h1 class="bigTitle">封面圖片</h1>
                <div class="upload aspect-ratio-1-1_global_style">
                  <img v-if="img" :src="img" class="uploadImg" />
                  <div v-else class="upload_icon">
                    <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                    <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                  </div>
                  <input type="file" @change="uploadImg" accept="image/*" class="img_input" />
                </div>
                <vs-button v-if="img" @click="img = ''" transparent danger style="margin-right: 0; margin-left: auto">移除</vs-button>
              </div>
              <div class="box">
                <h1 class="bigTitle">顯示設定</h1>
                <vs-checkbox v-model="isrtime">顯示發佈日期於列表</vs-checkbox>
              </div>

              <!-- 2.2.2 在此分類中的商品 -->
              <div class="box" v-if="!isNewBlog">
                <div class="flex items-center justify-between mb-4">
                  <h1 class="bigTitle">在此部落格中的文章</h1>
                  <router-link
                    v-if="posts.length > 0"
                    :to="{
                      name: 'posts',
                      query: {
                        blog: $route.params.id,
                      },
                    }"
                  >
                    <vs-button transparent color="success" size="mini"><span class="text-sm">查看全部</span></vs-button></router-link
                  >
                </div>
                <div class="space-y-2" v-if="posts.length > 0">
                  <div v-for="(item, index) in posts" :key="index" class="flex items-center space-x-2">
                    <div
                      class="w-10 h-10 items-center justify-center flex border border-gray-border rounded-lg overflow-hidden flex-shrink-0"
                    >
                      <img v-if="item.img" class="w-full h-full object-cover" :src="item.img" />
                      <svg-icon v-else icon-class="image" className="gray-border sm"></svg-icon>
                    </div>
                    <p class="">{{ item.title }}</p>
                  </div>
                </div>
                <p v-else class="text-gray-divide text-center py-5">尚無任何文章</p>
              </div>
            </div>
          </section>

          <hr />

          <section class="flex items-center justify-between">
            <vs-button @click="deleteBlog_dialog = true" v-if="!isNewBlog" size="large" color="danger" border>
              <span class="font-medium">刪除部落格</span>
            </vs-button>
            <vs-button to="/admin/pages" v-else size="large" border>
              <span class="font-medium">取消</span>
            </vs-button>
            <vs-button size="large" ref="saveBlog" :disabled="invalid" @click="saveBlog(invalid)">
              <span class="font-medium">儲存部落格</span>
            </vs-button>
          </section>
        </main>
      </div>
    </ValidationObserver>

    <!-- 骨架屏 -->
    <main v-if="!is_API_loaded && !isNewBlog" class="router-view-container space-y-4">
      <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />

        <div v-if="!isNewBlog" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      <skeleton v-if="!isNewBlog" class="lg:hidden" width="80%" height="40px" />

      <section class="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div class="w-full md:w-14/20 space-y-4">
          <article class="box">
            <skeleton />

            <div>
              <skeleton height="200px" />
            </div>
          </article>

          <article class="box">
            <skeleton />
          </article>
        </div>

        <div class="flex-grow space-y-4">
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
          <div class="box">
            <skeleton />
            <skeleton />
            <skeleton />
          </div>
        </div>
      </section>

      <hr />

      <section class="flex items-center justify-between">
        <skeleton width="82px" />
        <skeleton width="82px" />
      </section>
    </main>

    <!-- 刪除部落格 dialog -->
    <vs-dialog overflow-hidden blur v-model="deleteBlog_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除此部落格？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的部落格無法復原，在此部落格底下的文章也會一併刪除，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deleteBlog_dialog = false">取消</vs-button>
          <vs-button @click="deleteBlog" size="large" transparent color="danger" ref="deleteBlogBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'blog',
  data() {
    return {
      is_API_loaded: false,
      isNewBlog: false,
      title: '',
      seoTitle: '',
      seoUrl: '',
      seoContent: '',
      seoSectionOpen: false,
      titleSync: true,
      urlSync: true,
      deleteBlog_dialog: false,
      alertAfterCreated: false, // 新增成功後呈現alert訊息
      alertTitle: '',
      img: '',
      wlink: '',
      isrtime: true,
      posts: [], // 在此部落格中的文章
    }
  },
  computed: {
    _wlink() {
      return this.$store.state.getWebData.param.wlink + this.wlink
    },
  },
  watch: {
    $route(to, from) {
      this.isNewBlogFunc()
    },
    seoTitle(val) {
      if (val !== this.title) this.titleSync = false
      if (val === '') {
        // this.seoTitle = this.title
        this.titleSync = true
      }
    },
    seoUrl(val) {
      if (val !== this.title || this.isNewBlog === false) this.urlSync = false
      if (val === '') {
        // this.seoUrl = this.title
        this.urlSync = true
      }
    },
  },
  created() {
    this.isNewBlogFunc()
  },
  methods: {
    isNewBlogFunc() {
      // 判斷是新增部落格 還是 編輯部落格
      this.$route.name === 'new-blog' ? (this.isNewBlog = true) : (this.isNewBlog = false)

      if (!this.isNewBlog) this.loadBlog()
    },
    titleChange(value) {
      // 若sync = true，賦值到seo
      if (this.titleSync) this.seoTitle = value
      if (this.urlSync) this.seoUrl = value
    },
    // 2.5.2 部落格-讀取
    loadBlog() {
      this.$axios({
        url: 'front/pages/blogs/getBlogsUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          // console.log(res.data)
          this.is_API_loaded = true
          let data2 = res.data.Data.data2
          this.title = data2.title
          this.content = data2.cont
          this.img = data2.pcimg
          this.seoTitle = data2.mtitle
          this.seoContent = data2.mdescs
          this.seoUrl = data2.mpages
          this.wlink = data2.wlink
          this.posts = data2.item
          data2.isrtime ? (this.isrtime = true) : (this.isrtime = false)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.5.3 部落格-新增
    // 2.5.4 部落格-修改
    saveBlog(invalid) {
      this.alertAfterCreated = false
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveBlog,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let url = ''

      let mpages = '' // 網頁網址
      if (this.seoUrl === '') {
        mpages = this.title.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      } else {
        mpages = this.seoUrl.replace(/[&|\*^'"{}[\]%$:;=#@!/?\s*\\\\<>,.()~+_`]/g, '-')
      }

      let data = {
        lang: 0,
        title: this.title,
        pcimg: this.img,
        isrtime: this.isrtime ? 1 : 0,
        mtitle: this.seoTitle ? this.seoTitle : (this.seoTitle = this.title),
        mdescs: this.seoContent ? this.seoContent : (this.seoContent = this.title),
        mpages: mpages,
      }

      if (this.isNewBlog) {
        url = 'front/pages/blogs/setBlogs.php'
      } else {
        url = 'front/pages/blogs/uBlogs.php'
        data.sbno = this.$route.params.id
      }

      this.$axios({
        url: url,
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()

          this.$myVS.openNoti(res.data.Message, '', undefined)

          // 若是新增
          if (this.isNewBlog) {
            this.alertAfterCreated = true // 呈現alert
            this.alertTitle = this.title
            this.$router.replace({
              // 跳轉到新頁面
              name: 'edit-blog',
              params: {
                id: res.data.Data.sbno,
              },
            })
          } else {
            this.loadBlog()
          }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 2.5.5 部落格-刪除
    deleteBlog() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBlogBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/pages/blogs/dBlogs.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbno: this.$route.params.id,
        },
      }).then((res) => {
        if (res.data.Success) {
          loading.close() // 關閉按鈕loading
          this.deleteBlog_dialog = false // 關閉dialog
          this.$myVS.openNoti(res.data.Message, '', undefined)

          this.$router.replace({
            name: 'blogs',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    uploadImg(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.img = e.target.result
      }
      e.target.value = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

// seo網址
::v-deep #vs-input--noBorderInput {
  border: none;
  padding-left: 0px;
}

.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
  &.aspect-ratio-1-1_global_style {
    padding-bottom: calc(100% - 4px);
  }
}

.uploadImg {
  @apply absolute top-1/2 transform -translate-y-1/2;
  width: 100%;
  @screen md {
    max-width: 261px;
  }
}

.upload_icon {
  @apply flex flex-col items-center absolute top-1/2 transform -translate-y-1/2;
}

.img_input {
  @apply border-2 absolute z-10 h-full w-full cursor-pointer opacity-0 top-1/2 transform -translate-y-1/2;
}
</style>
